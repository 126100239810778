import Vue from 'vue'
import App from './App.vue'
import router from './router'
import baiduAnalytics from 'vue-baidu-analytics'
Vue.config.productionTip = false
Vue.use(baiduAnalytics, {
  router: router,
  siteIdList: [
    '819d0c12edfa1fc8ee48a0477a42c9bf'
  ],
  isDebug: false
})
new Vue({
  render: h => h(App),
    router
}).$mount('#app')
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '东南迈峰'
  }
  next()
})
