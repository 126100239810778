<template>
    <div class="player-container">
        <vue-core-video-player  :src="videoSource" :cover="cover" :title= "title"  autoplay loop="true"/>
    </div>
</template>

<script>
    import Vue from 'vue';
    import VueCoreVideoPlayer from 'vue-core-video-player'
    Vue.use(VueCoreVideoPlayer)
    export default {
        name: "Test",
        data() {
            return {
                videoSource: [{
                    src: 'https://media.vued.vanthink.cn/sparkle_your_name_am360p.mp4',
                    resolution: 360,
                }, {
                    src: 'https://media.vued.vanthink.cn/sparkle_your_name_am720p.mp4',
                    resolution: 720,
                }, {
                    src: 'https://media.vued.vanthink.cn/y2mate.com%20-%20sparkle_your_name_amv_K_7To_y9IAM_1080p.mp4',
                    resolution: 1080
                }],
                cover: "https://img1.wxzxzj.com/vpc-example-cover-your-name-c.png",
                title: "你的名字"
            }
        },
        methods: {
            paly() {
                window.console.log("play");
            },
            pause(){
                window.console.log("pause");
            }
        }
    }
</script>

<style>
    .play-pause-layer .btn-control {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        margin-left: -30px;
        margin-top: -30px;
        background-color: transparent !important;
        border-radius: 30px;
    }
    .btn-control svg {
        opacity: .85;
        -webkit-transition: opacity .1s ease;
        transition: opacity .1s ease;
        fill: #fff !important;
    }
    path {
        fill: #fff;
        stroke: white;
    }
    rect {
        fill: #fff;
    }
</style>