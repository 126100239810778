/**
 * Created by yangxiaoshuo on 2019/10/15.
 */
import Vue from 'vue'
import Router from 'vue-router'
import wuxiandian from './components/wuxiandian.vue'
import Test from './components/Test.vue'
Vue.use(Router)
export default new Router ({
    routes: [
        {
            path:'/wuxiandian',
            name:'无线电',
            meta:{title: '无限电'},
            component: wuxiandian
        },
        {
            path:'/Test',
            name:'测试',
            meta:{title: '测试'},
            component: Test
        }
    ]
})
